import { mapActions, mapGetters, mapMutations } from 'vuex';
import {find} from 'lodash';

import dialogView from './dialog-view/index.vue'
import templatesFilter from '../../components/email-templates-filter/index.vue'

export default {
    name: 'setting-layouts',
    data () {
        return {
            page            : 1,
            showRemoveDialog: false,
            removedItem     : null
        }
    },
    components: {
        dialogView,
        templatesFilter
    },
    computed: {
        ...mapGetters({
            list            : 'settingTemplates/list',
            limit           : 'settingTemplates/limit',
            listLength      : 'settingTemplates/listLength',
            isLoading       : 'settingTemplates/isLoading',
            isListLoading   : 'settingTemplates/isListLoading',
            openDialogView  : 'settingTemplates/openDialogView',
            basicCountries  : 'setting/basicCountries',
            templateTypes   : 'settingTemplates/templateTypes'
        }),
        headers () {
            return [
                {
                    text    : 'Name',
                    value   : 'name'
                },
                {
                    text    : 'Key',
                    value   : 'key'
                },
                {
                    text    : 'Status',
                    value   : 'status',
                    sort    : (a, b) => {
                        const nameA = a.data.title.toLowerCase();
                        const nameB = b.data.title.toLowerCase();
                        
                        if (nameA > nameB) {
                            return -1
                        }
                        
                        if (nameA < nameB) {
                            return 1
                        }
                        
                        return 0
                    }
                },
                {
                    text    : 'Type',
                    value   : 'type'
                },
                {
                    text    : 'Actions',
                    sortable: false,
                    value   : 'actions'
                }
            ]
        }
    },
    created () {
        this.fetchList();
        this.getTemplateTypes();
        
        if (!this.basicCountries.length) this.fetchBasicCountries()
    },
    methods: {
        ...mapActions({
            fetchList           : 'settingTemplates/GET_TEMPLATE_LIST',
            getTemplateTypes    : 'settingTemplates/GET_TEMPLATES_TYPES',
            remove              : 'settingTemplates/DELETE',
            fetchBasicLocales   : 'setting/GET_BASIC_LOCALES',
            fetchBasicCountries : 'setting/GET_BASIC_COUNTRIES'
        }),
        ...mapMutations({
            changeSkip      : 'settingTemplates/CHANGE_SKIP',
            changeDialogView: 'settingTemplates/CHANGE_DIALOG_VIEW',
            changeEditId    : 'settingTemplates/CHANGE_EDIT_ID',
        }),
        getType(typeId){
            return find(this.templateTypes, ['id', typeId])
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1))
            this.$vuetify.goTo(0);
            
            this.fetchList()
        },
        changeDialog (status = false, item = null) {
            this.changeEditId(item.id)
            this.changeDialogView(status)
        },
        changeDialogRemove (item) {
            this.showRemoveDialog = !!item
            this.removedItem = item
        },
        removeListItem () {
            this.remove(this.removedItem).then(() => {
                this.showRemoveDialog = false
                this.$toasted.success(this.$t('success_deleted'))
            })
        }
    }
}
