import { mapActions, mapGetters, mapMutations } from 'vuex'
import general from '../general/index.vue'
import translations from '../translations/index.vue'

export default {
  name: 'setting-layouts-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    translations,
    general
  },
  watch: {
    openDialogView () {
      this.dialog = this.openDialogView
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'settingTemplates/currentItem',
      openDialogView: 'settingTemplates/openDialogView',
      basicLocales: 'setting/basicLocales',
      editId: 'settingTemplates/editId',
	  isLoading: 'settingTemplates/isLoading',
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Translations',
          slug: 'translations',
          id: 3,
          disabled: !this.currentItem
        }
      ]
    }
  },
  created () {
    if (!this.basicLocales.length) {
      this.fetchBasicLocales()
    }
    if (this.editId) {
      this.fetchItem()
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'settingTemplates/GET_LIST_ITEM',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES'
    }),
    ...mapMutations({
      changeDialogView: 'settingTemplates/CHANGE_DIALOG_VIEW',
      changeEditId: 'settingTemplates/CHANGE_EDIT_ID',
      setContent: 'settingTemplates/SET_ITEM'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.changeDialogView(false)
      this.changeEditId('')
    },

	save(){
	  this.$refs['general'].$emit('event-save');
	}
  },
  destroyed () {
    this.setContent(null)
  }
}
