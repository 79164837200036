import validation from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import MainEditor from '@/components/editor/index.vue'
export default {
  name: 'setting-layouts-translations-item',
  props: {
    locale: {}
  },
  components: {
    MainEditor
  },
  mixin: [validation],
  data () {
    return {
      valid: false,
      payload: {
        locale: this.locale,
        content: '',
        subject: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'settingTemplates/currentItem',
      isTranslationLoading: 'settingTemplates/isTranslationLoading'
    }),
    currentTranslation () {
      return this.currentItem.translations.data.find(e => e.locale === this.locale)
    }
  },
  created () {
    if (this.currentTranslation) {
      Object.assign(this.payload, this.currentTranslation)
    }
  },
  methods: {
    ...mapActions({
      update: 'settingTemplates/UPDATE_TRANSLATION'
    }),
    createRequestPayload () {
      const formData = new FormData()

      for (const i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }
      if (this.currentTranslation && this.currentTranslation.hasOwnProperty('id')) {
        formData.append('_method', 'patch')
        formData.append('id', this.currentTranslation.id)
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.update(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  }
}
