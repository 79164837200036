import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'setting-email-layouts-filter',
    data () {
        return {
            filter: {
                keywords    : '',
                countries   : '',
                type_id     : ''
            }
        }
    },
    computed: {
        ...mapGetters({
            filterCountries : 'settingTemplates/filterCountries',
            filterKeywords  : 'settingTemplates/filterKeywords',
            basicCountries  : 'setting/basicCountries',
            templateTypes   : 'settingTemplates/templateTypes'
        })
    },
    created () {
        if (!this.accessCountries) {
            this.fetchCountries().then(() => { this.setFilters() })
        }
        
        this.setFilters()
    },
    methods: {
        ...mapActions({
            fetchCountries      : 'setting/GET_COUNTRIES',
            fetchList           : 'settingTemplates/GET_LIST',
            fetchTemplateList   : 'settingTemplates/GET_TEMPLATE_LIST',
        }),
        ...mapMutations({
            changeFilter        : 'settingTemplates/CHANGE_LIST_FILTER',
            setListLength       : 'settingTemplates/SET_LIST_LENGTH',
            changeSkip          : 'settingTemplates/CHANGE_SKIP'
        }),
        setFilters () {
            this.filter.countries   = this.filterCountries;
            this.filter.keywords    = this.filterKeywords;
        },
        sendRequest: debounce(function () {
            this.fetchTemplateList()
        }, 500),
        changeFilterLocal (val, type) {
            this.changeFilter({
                type    : type,
                value   : val
            });
            
            this.sendRequest()
            
            // reset
            this.setListLength(0)
            this.changeSkip(0)
        }
    }
}
