import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import course from '@/mixins/course'
import { snakeCase, find } from 'lodash'

export default {
  name: 'setting-layouts-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      payload: {
        name: '',
        statusId: '',
        layoutId: '',
        typeId: '',
        key: ''
      }
    }
  },
  watch: {
    currentItem () {
      if (this.currentItem && this.editId) {
        this.setData()
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'settingTemplates/isLoading',
      currentItem: 'settingTemplates/currentItem',
      settings: 'templates/settings',
      list: 'settingTemplates/list',
      editId: 'settingTemplates/editId',

      basicCountries: 'setting/basicCountries',

      layouts: 'settingLayouts/list',
      isLayoutsListLoading: 'settingLayouts/isListLoading',

      templateTypes: 'settingTemplates/templateTypes'
    })
  },
  created () {
    if (this.currentItem) {
      this.setData()
    }
    if (!this.settings) this.fetchSettings()
    if (!this.layouts.length) this.fetchLayouts()
    if (!this.basicCountries.length) this.fetchBasicCountries()

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      update: 'settingTemplates/UPDATE',
      create: 'settingTemplates/CREATE',
      fetchSettings: 'templates/GET_SETTING',
      fetchLayouts: 'settingLayouts/GET_LIST',
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES'
    }),
    ...mapMutations({
      setLayoutsList: 'settingLayouts/SET_LIST'
    }),
    setData () {
      Object.assign(this.payload, this.currentItem);
    },
    createRequestPayload () {
      const formData = new FormData()

      for (const i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }

      this.currentItem ? formData.append('_method', 'patch') : ''
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.setData();
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'));
		  })
        }
      }
    }
  },
  destroyed () {
    this.setLayoutsList([])
  }
}
